:host {
  display: none;
}

.abm-menu-wrapper {
  .abm-menu {
    display: flex;
    flex-flow: column nowrap;
    gap: 1px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    cursor: pointer;
    font: inherit;

    ::ng-deep {
      abm-menu-item:first-of-type {
        border-radius: 8px 8px 0 0;
      }

      abm-menu-item:last-of-type {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
