@use 'color' as *;
@use 'functions' as *;

@mixin font($font-family: 'Roboto', $size: false, $color: false, $weight: false, $line-height: false) {
  font-family: $font-family;

  @if $size {
    font-size: $size;
  }

  @if $color {
    color: $color;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $line-height {
    line-height: $line-height;
  }
}

@mixin subheader() {
  $offset: 8px;
  $header-height: 56px;

  position: relative;
  top: calc(-#{$header-height});
  display: flex;
  align-items: center;
  width: calc(100% - $offset * 2);
  padding: $offset;
  margin: 0 $offset calc(-#{$header-height} + #{$offset}) $offset;
  border-radius: 0 8px 8px 8px;
  z-index: 2;
}

@mixin before($content: '', $position: absolute, $top: 0, $left: 0) {
  @if $content {
    content: $content;
  }

  @if $position {
    position: $position;
  }

  @if $top {
    top: $top;
  }

  @if $left {
    left: $left;
  }
}

@mixin icon-info($color: red) {
  $color: rgba(red($color), green($color), blue($color), alpha($color));
  display: inline-block;
  width: 22px;
  height: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_614_12429)'%3E%3Cpath d='M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z' stroke='#{$color}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.3125 10.3125H11V15.125H11.6875' stroke='#{$color}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.8281 8.25C11.3977 8.25 11.8594 7.78829 11.8594 7.21875C11.8594 6.64921 11.3977 6.1875 10.8281 6.1875C10.2586 6.1875 9.79688 6.64921 9.79688 7.21875C9.79688 7.78829 10.2586 8.25 10.8281 8.25Z' fill='#{$color}'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_614_12429'%3E%3Crect width='22' height='22' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

@mixin popup-header($padding: 0px) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
  border-bottom: 1px solid $border;

  @include font($font-family: 'Roboto', $weight: 500, $size: 20px, $line-height: 130%, $color: var(--text-color));

  .close-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: unset;
    outline: none;
    border: none;
    color: map-get($map: $gray, $key: 300);
    cursor: pointer;

    &:hover {
      color: map-get($map: $gray, $key: 400);
    }
  }
}

@mixin sku-edit-page {
  @include edit-page;
  grid-template-rows: auto 0 1fr auto;
}

@mixin edit-page {
  display: grid;
  min-height: 100%;
  grid-template-rows: auto 1fr auto;

  .title {
    @include font($weight: 500, $size: 20px, $color: $basic-black);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  app-edit-page-header,
  app-sku-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  app-edit-page-footer {
    position: sticky;
    bottom: 0;
    @content;
  }

  div[actions] {
    display: flex;
    align-items: center;

    app-button {
      margin-left: 12px;
    }
  }
}

@mixin insight-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;

  &.expired {
    background: rgba(255, 152, 31, 0.12);
  }

  &.out-of-stock {
    background: rgba(61, 204, 54, 0.12);
  }

  &.next-status {
    background: rgba(255, 61, 71, 0.1);
  }

  &.listing {
    background: rgba(255, 152, 31, 0.12);
  }

  &.pending-deletion {
    background: rgba(235, 234, 254, 1);
  }
}
