@use 'theme' as theme;
@use 'color' as *;
@use 'mixin' as *;
@use 'theme/paginator' as *;
@use './app/modules/spinner/spinner';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies(mat.m2-define-typography-config($font-family: Roboto));
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$assortment-primary: mat.m2-define-palette(theme.$assortment-primary);
$assortment-accent: mat.m2-define-palette(theme.$assortment-accent);

// The warn palette is optional (defaults to red).
$assortment-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$assortment-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $assortment-primary,
      accent: $assortment-accent,
      warn: $assortment-warn,
    ),
  )
);

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($assortment-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

body {
  --mdc-typography-button-letter-spacing: 0;

  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: map-get($map: $gray, $key: 0);
}

h4 {
  @include font($font-family: 'Montserrat', $weight: 700, $size: 24px, $color: $black);
}

a {
  text-decoration: none;
}

.tooltip-icon {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-left: 4px;
  font-size: inherit;
  width: inherit;
  height: inherit;
  vertical-align: top;
  cursor: help;
}

// paginator
.page-paginator {
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  @include paginator-sizes(20px, 3px, 26px, 26px, 6px);
  @include paginator-icon(var(--gray-400), 14px);
  @include paginator-page-info-text(Roboto, 12px, 400, var(--gray-400));
  @include paginator-page-number-text(Roboto, 12px, 400, var(--secondary-500), var(--white));
  @include paginator-button-style(
    #e7e7ea,
    3px,
    var(--white),
    linear-gradient(107.81deg, #6c63e8 0%, var(--secondary-500) 98.08%)
  );

  position: sticky;
  bottom: 0;
  background: $white;
  filter: drop-shadow(0px -2px 10px rgba(0, 0, 0, 0.08));
}

// libs
.abm-menu-wrapper {
  @include font($weight: 400, $size: 14px, $color: $basic-black);
}

// errors
.error-message {
  color: var(--system-red, red);
  font-size: 10px;
}

.highlight-text {
  display: inline;
  background: #dae8fc;
}

// select overlay
.matrix-product-history-select {
  --select-option-font-size: 14px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}
