@use 'color' as *;
@use 'mixin' as *;

.mat-calendar-body-selected {
  background: $button-gradient;
  background-color: unset;
  color: $white;
}

mat-calendar {
  .mat-calendar-content tr[role='row'] td.mat-calendar-body-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-calendar-body-cell.mat-calendar-body-selected {
    background: $button-gradient;
    color: $white;
  }

  .mat-calendar-body-cell-content.mat-calendar-body-today {
    background-color: map-get($map: $secondary, $key: 100) !important;
    border-color: map-get($map: $secondary, $key: 400) !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: map-get($map: $secondary, $key: 200);
  }
}

// snack bar
mat-snack-bar-container.mdc-snackbar {
  --mdc-snackbar-container-color: var(--white);
  --mdc-snackbar-container-shape: 8px;
  --mdc-snackbar-supporting-text-color: var(--gray-500);

  &.mat-mdc-snack-bar-container {
    margin-right: 16px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    background: $white;
    border: 1px solid $border;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    @include font($size: 14px, $color: $black);
  }

  .mat-mdc-snack-bar-label {
    max-width: 474px;
  }
}

// dialog
.custom-create-dialog,
.file-uploader-dialog {
  --mat-dialog-container-max-width: 100vw;

  mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

// form field
mat-form-field {
  &.mat-mdc-form-field {
    font-size: inherit;
    justify-content: center;

    .mdc-text-field--no-label {
      .mat-mdc-form-field-infix {
        padding: 0;
      }
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    display: flex;
    align-items: center;
    background-color: var(--white);
    padding: 0;
    border-radius: 4px;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--no-label {
    .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      padding: 0 !important;
      width: unset;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 38px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// tabs
.mat-mdc-tab,
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--secondary-500);
  --mat-tab-header-label-text-letter-spacing: 0;

  .mat-mdc-tab-label-container {
    .mat-mdc-tab-labels {
      .mat-mdc-tab {
        flex-grow: 0;
      }

      .mdc-tab {
        font-weight: 500;
        font-size: 18px;

        &__content {
          .mdc-tab__text-label {
            color: var(--gray-300);
          }
        }

        &--active {
          .mdc-tab__text-label {
            color: var(--secondary-500);
          }
        }
      }
    }
  }

  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0;
  }
}
.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  border-top: 1px solid $border !important;
  width: 100%;
  bottom: 0;
  right: 0;

  app-button {
    width: 150px;
    margin-left: 24px;

    .secondary {
      background: transparent !important;
      color: map-get($secondary, 500) !important;
    }
  }
}
